#registerBody {
  @extend %rowFlex;
  background-color: $greenYellow;
  min-width: 100vw;
}

#registerForm {
  @extend %columnFlex;
  min-height: calc(100vh - (2 * #{$hFHeight}));
}

.registerSections {
  @extend %columnFlex;
  width: 85vw;
  max-width: 250px;
  max-height: 200px;
  margin: 10px 0px 10px 0px;
}

#backToLoginLink {
  color: $yellow;
}

@media (min-width: $iPadWidth) {
  .registerSectionText {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: black;
  }
  .registerWelcomeText {
    color: black;
    letter-spacing: 0.3pt;
    line-height: 20pt;
    width: 400px;
    border-radius: $corners;
    box-shadow: $boxShadow;
    padding: $boxPadding;
    box-sizing: border-box;
    background-color: $greenBlue;
    margin-left: 40px;
  }
}

@media (max-width: $iPadWidthL1) {
  .registerSectionText {
    display: none;
  }
  .registerWelcomeText {
    display: none;
  }
}
