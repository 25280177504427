.weedDescriptionBox {
  @extend %columnFlex;
}

#descParagraph {
  width: 80vw;
  max-width: 600px;
}

#manageDescLegend {
  color: black;
}

#descImage {
  width: 100vw;
  margin: 40px 0px 40px 0px;
  max-width: 800px;
}
