.toPrint,
.nativeSelectorVis {
  display: none;
}

#nativeSearchResults {
  flex: 1 1 auto;
}

#searchContainer {
  border: solid $yellow;
}

.searchInputAndLabel {
  @extend %wrapFlex;
}

.searchLabels {
  margin: 0px 5px 0px 20px;
  color: $yellow;
}

#addedNativesAside,
#searchInputs,
.bloomTimeBox,
.nativeAddedLI {
  padding: 15px;
}

#addedNativesAside {
  position: fixed;
  top: calc(#{$hFHeight} + 12px);
  border: solid $yellow;
  max-height: calc(100% - (#{$hFHeight} + 30px));
  overflow-y: scroll;
}

#printButton {
  float: right;
}

.addedHeaders {
  @include fitContent(height);
  margin: auto;
}

.nativeAddedInputs {
  @extend %wrapFlex;
  width: 100%;
}

.searchResultImage:hover {
  border: solid thick $yellow;
}
.nativeAddedPic:hover {
  border: solid thick red;
}

#eSpringH,
#lSpringH,
#summerH,
#fallH {
  color: $brick;
}

.nativeAddedLI {
  list-style: none;
  background-color: $greenYellow;
  padding: 10px;
  border: solid black;
  margin: 7.5px 0px 7.5px 0px;
}

.nativeAddedPic {
  float: left;
  margin-right: 10px;
  object-fit: cover;
  height: 50px;
  width: 50px;
}

.nativeAddedText {
  margin: 10px 5px 10px 5px;
  width: 75%;
  resize: none;
  font-size: 12pt;
  font-family: $font-stack;
}

.nativeAddedText:disabled {
  background: transparent;
  color: black;
}

.bloomTimeBox {
  background-color: $lightBlue;
  @include fitContent(height);
  margin: 10px 0px 10px 0px;
}
.nativeThumbnail {
  border: solid black;
  display: inline-flex;
  @extend %columnFlex;
  width: 220px;
  height: 375px;
  padding: 5px;
  margin: 5px;
  background-color: $greenYellow;
}

.searchResultImage {
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin: 0px 0px 10px 0px;
}

.editProjectNotesButton {
  margin: 0px;
}

@media (min-width: $iPadWidth) {
  #addedNativesAside {
    right: 10px;
    width: 35vw;
  }
  .bloomTimeBox {
    width: calc(35vw - 50px);
  }
  #searchContainer {
    margin: 0px 0px 20px 20px;
  }
  #searchInputs,
  #nativeSearchResults,
  #searchContainer {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  #nativeSearchResults,
  #searchContainer {
    margin-right: calc(35vw + 40px);
  }
}

@media (max-width: $iPadWidthL1) {
  .nativeSelectorVis {
    display: inline-block;
  }

  #addedNativesAside,
  #searchContainer,
  #nativeSearchResults {
    width: 90vw;
    margin: 0px;
  }

  .addedClosed {
    display: none;
  }
  .addedOpen {
    display: inline-block;
    position: static;
  }
  .searchClosed {
    visibility: hidden;
  }
  .searchOpen {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}
