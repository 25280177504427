.d3Legend,
.legendBody,
#timelineHeaderBox,
.timelineSVG,
#legendContainer {
  max-width: 700px;
  width: 90vw;
}

#d3Container {
  @extend %wrapFlex;
}

#timelineHeaderBox {
  @extend %wrapFlex;
  width: 100%;
  margin-bottom: 15px;
}
#growingInfoBox {
  @extend %brickBox;
  max-width: 400px;
  width: 85vw;
  padding: $boxPadding;
}

.timelineSVG {
  border: solid $brick;
  margin-bottom: 30px;
}
.timelineAxis {
  color: $brick;
}

#legendContainer {
  @extend %columnFlex;
}

.legendBody {
  @extend %brickBox;
  @include fitContent(height);
  margin: 2.5px 0px 2.5px 0px;
  width: 90vw;
  padding: 0px;
  display: flex;
  align-items: center;
  max-width: 550px;
}

.legendText {
  font-size: 12pt;
  color: $lightBlue;
  font-weight: bold;
  letter-spacing: 1.5pt;
  text-align: center;
}

.colorCode {
  width: 30px;
  min-width: 30px;
  height: 30px;
  float: left;
  margin-right: 15px;
}
#legendHeader {
  color: $brick;
  margin-bottom: 20px;
  font-weight: bold;
}

#legendMessage {
  color: $brick;
  max-width: 400px;
}
@media (min-width: 1460px) {
  #legendContainer {
    margin-left: 20px;
  }
}
@media (max-width: 1459px) {
  #d3Container {
    max-width: 600px;
  }
  #legendContainer {
    margin-left: 0px;
  }
}

@media (max-width: $iPhoneWidth) {
  .hidden {
    display: none;
  }
  .legendText {
    font-size: 11pt;
  }
}
