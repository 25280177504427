nav {
  display: flex;
  width: 100vw;
  height: $hFHeight;
}

.linkBox {
  display: flex;
  z-index: 1;
}

.weedLinkBox {
  position: relative;
  background-color: $greenBlue;
  padding: 15px;
  transition: all 0.3s;
}
.hamburgerPatties,
.invertColors {
  margin: 2px 0px 2px 0px;
  width: 25px;
  height: 4px;
}

@media (min-width: $iPadWidth) {
  #weedsMenu {
    height: 14pt;
  }
  .weedLinkBox {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .weedLinkBoxOpened {
    top: 0px;
    z-index: 1;
    background-color: $greenBlueTransparent;
  }

  .weedLinkBoxClosed {
    top: -150px;
    z-index: -1;
    opacity: 0;
  }
  .linkBox {
    position: fixed;
    top: 0px;
    left: 0px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: $greenBlue;
    color: $yellow;
    justify-content: space-around;
    width: 100vw;
  }

  .hamburger {
    display: none;
  }
}

@media (max-width: $iPadWidthL1) {
  nav {
    justify-content: flex-end;
  }
  .greenYellowBackground {
    background-color: $greenYellow;
  }
  .hamburger {
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    margin: auto 20px auto 20px;
    transition: all 0.3s;
  }
  .invertColors {
    background-color: $greenBlue;
  }

  .hamburgerPatties {
    background-color: $greenYellow;
  }
  .linkBox {
    position: static;
    flex-direction: column;
    height: 415px;
    background-color: $greenBlueTransparent;
    color: $yellow;
    transition: all 0.4s;
    z-index: 2;
    padding: 15px;
  }

  .weedLinkBox {
    display: inline-flex;
    flex-direction: column;
    padding: 0px;
  }

  #weedsMenu {
    border-bottom: solid $yellow;
    border-top: solid $yellow;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #weedInfo {
    @include fitContent(width);
    text-align: left;
    margin: 15px 0px 15px 0px;
    border-bottom: 1px solid $yellow;
    padding-bottom: 3px;
  }

  // linkbox start
  .linkBoxClosed {
    @include transform(translate(275px, $hFHeight));
  }
  .linkBoxOpened {
    @include transform(translate(70px, $hFHeight));
  }
}
