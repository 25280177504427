#submitResetBody {
  justify-items: center;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  height: fit-content;
  max-height: 100vh;
  @extend %brickBox;
  width: 90vw;
  max-width: 500px;
  padding: 45px;
}

#submitResetBody > article,
#submitResetBody > h1,
#submitResetBody > h4 {
  margin: 10px;
}

#submitResetForm {
  @extend %wrapFlex;
  max-width: 250px;
  @extend %yellowBox;
}

#resetTitle {
  margin: 20px 20px;
}

#submitResetForm > button {
  margin-left: 20px;
  background-color: $greenBlue;
  color: $yellow;
}

#submitResetForm > input {
  text-align: center;
}

@media (max-width: calc($iPhoneWidth + 20px)) {
  #submitResetBody > h1 {
    font-size: 20pt;
  }

  #submitResetBody > article > h4,
  #submitResetBody > h4 {
    font-size: 13pt;
  }

  #submitResetForm > input {
    font-size: 11pt;
  }

  #submitResetForm > button {
    font-size: 11pt;
  }
}
