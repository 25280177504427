#weedSearchBody,
#weedSearchForm,
#searchResultsBox {
  width: 100%;
}

#weedSearchBody {
  background-color: $greenYellow;
  @extend %wrapFlex;
}

#apbLabel {
  margin: 0px 5px 0px 20px;
  color: $greenBlue;
}

#searchResultsBox {
  min-height: calc(100vh - (2 * #{$hFHeight}));
  margin-top: 80px;
  @extend %wrapFlex;
}

@media (min-width: 441px) and (max-width: 492px),
  (max-width: 870px) and (min-width: 768px) {
  #showAllSpeciesButton {
    margin-top: -10px;
  }
}

@media (max-width: 361px) {
  #showAllSpeciesButton {
    margin-top: -4px;
  }
  #weedSearchForm {
    min-height: 75px;
  }
}

@media (min-width: $iPadWidth) {
  #weedSearchForm {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 6vh;
    left: 0px;
  }
}
@media (max-width: $iPadWidthL1) {
  #weedSearchForm {
    position: relative;
    top: 0px;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: $greenYellow;
    height: 5vh;
  }
}

// Thumbnail

.searchFigures,
.searchLinks {
  @extend %blue-greenBox;
  @extend %columnFlex;
  width: 225px;
  height: 350px;
  margin: 5px;
}

figcaption {
  @include fitContent(height);
}

.searchImages {
  height: 250px;
  width: 200px;
  object-fit: cover;
}
