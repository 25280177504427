#resetReqBox {
  justify-items: center;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  height: fit-content;
  @extend %brickBox;
  width: 90vw;
  max-width: 500px;
}

#resetReqBox > article > h4 {
  border-radius: $corners;
  background-color: $yellow;
  color: $brick;
}

#resetTitle {
  margin: 20px 20px;
}

#resetTitle + h3 {
  margin-bottom: 20px;
}

#resetReqButton {
  margin-left: 20px;
  background-color: $greenBlue;
  color: $yellow;
}

#resetRequestForm {
  @extend %wrapFlex;
}

@media (max-width: calc($iPhoneWidth + 20px)) {
  #resetReqBox > * {
    font-size: calc(100% + 3pt);
  }
}
@media (max-width: calc($iPhoneWidthL1 - 20px)) {
  #resetReqBox > * {
    font-size: calc(100% + 1pt);
  }
}
