footer {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $greenYellow;
  height: $hFHeight;
}

#donationLink {
  color: black;
}
