#timelineHeader {
  color: $brick;
  font-weight: bolder;
  margin-top: 30px;
}
#dashboardMain {
  min-height: calc(100vh - 2 * $hFHeight);
  @extend %columnFlex;
  padding-bottom: 40px;
}
#dashboardDecoration {
  @extend %columnFlex;
  @extend %lightBlueBox;
  // padding: 20px;
  @include fitContent(height);
  @include fitContent(width);
}

// DashDropdowns

#dropdownLegend {
  color: black;
}
select {
  margin: 3px;
  border-radius: $corners;
  font-size: 13pt;
}

.dropdownBox {
  @extend %yellow-GreenBox;
  @extend %columnFlex;
  margin: 40px 0px auto 0px;
  max-width: 900px;
}

// Switch Maker

.switchBody {
  border: dotted black thin;
  padding: 30px;
  width: 100%;
  margin: 5px 0px 5px 0px;
}
.switchPara {
  max-width: 950px;
}

[type='checkbox'] {
  opacity: 0;
}

.switchLabel {
  position: relative;
  right: 60px;
  top: 45px;
  font-weight: bold;
}

.switchPara {
  display: flex;
  justify-content: flex-start;
  height: 31px;
}

.switchFlexBox {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 31px;
}
.switch {
  position: relative;
  top: -60px;
  width: 60px;
  height: 31px;
  margin-right: 20px;
}
.treatmentDesc {
  margin: 10px 10px 10px 10px;
}
/* label containing two span elements will be flex box configured for equal space between elements */
.swtich label {
  right: 0px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}
/* The .switchSpan element will be positioned relatively with all transitions occuring within 0.3s  */
.switchSpan {
  position: relative;
  transition: all 0.3s;
}
/* Absolute position of pseudo-elements before and after the switchSpan, content for these pseudo elements will also be null before and after, which renders the element, but does not add content (text) */
.switchSpan::before,
.switchSpan::after {
  position: absolute;
  content: '';
}

/* The condition of the switchSpan element before checking/toggling should be as follows */
.switchSpan::before {
  top: -13px;
  left: 3px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  z-index: 1;
  transition: 0.4s;
  background: $lightBlue;
}

/* From MDN, The adjacent sibling combinator (+) separates two selectors and matches the second element only if it immediately follows the first element, and both are children of the same parent. 
  
  If the checkbox within the switch class is checked, and a label element exists that immediately follows this checkd apply this styling before the switchSpan element */
.switch [type='checkbox']:checked + label .switchSpan::before {
  @include transform(translateX(30px));
  top: -13px;
  height: 25px;
  width: 25px;
  border-radius: 20px;
  background: $greenBlue;
}
/* insert the following content after the switchspan */
.switch .switchSpan::after {
  width: 60px;
  height: 31px;
  border-radius: 20px;
  background: $brick;
}

/* From MDN, The adjacent sibling combinator (+) separates two selectors and matches the second element only if it immediately follows the first element, and both are children of the same parent. 
  
  If the checkbox within the switch class is checked, and a label element exists that immediately follows this checkbox and immediately precedes the switchSpan in the after pseudo state, apply the styling */
.switch [type='checkbox']:checked + label .switchSpan::after {
  height: 31px;
  width: 60px;
  border-radius: 20px;
  background: $yellow;
}
