$font-stack: Raleway, Trebuchet, Tahoma, Arial, sans-serif;
$greenBlue: #297373;
$greenBlueTransparent: rgba(42, 116, 116, 0.9);
$greenYellow: #a0d449;
$yellow: #ffcc33;
$brick: #af6652;
$lightBlue: #dcecec;
$corners: 5px;
$boxShadow: 0px 2px 2px 0px grey;
$hFHeight: 50px;
$boxPadding: 15px;
$iPadWidth: 768px;
$iPadWidthL1: 767px;
$iPhoneWidth: 430px;
$iPhoneWidthL1: 329px;
