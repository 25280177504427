/* Loading Screen Graphic */
#loaderBox {
  max-width: 700px;
  @extend %columnFlex;
  padding: 10%;
}

.svgContainer {
  width: 100%;
}

.loaderText {
  position: relative;
  text-align: center;
  font-weight: normal;
  font-size: 18px;
  color: $yellow;
  max-width: 700px;
  width: 80vw;
}

#cloud path,
#sun path {
  stroke: $yellow;
  stroke-width: 0.25;
  fill: $yellow;
}

#cloud path {
  fill: $greenBlue;
}

.rain {
  position: absolute;
  bottom: 20vh;
  left: 20vw;
  width: 60vw;
  height: 20vh;
}

.drop {
  opacity: 1;
  fill: $yellow;
  display: block;
  float: left;
  margin-left: 4vw;
  border-radius: 0px 0px 6px 6px;
  width: 1vw;
  height: 1vh;

  animation-name: drop;
  animation-duration: 350ms;
  animation-iteration-count: infinite;
}

.drop:nth-child(1) {
  animation-delay: -130ms;
}

.drop:nth-child(2) {
  animation-delay: -240ms;
}

.drop:nth-child(3) {
  animation-delay: -390ms;
}

.drop:nth-child(4) {
  animation-delay: -525ms;
}

.drop:nth-child(5) {
  animation-delay: -640ms;
}

.drop:nth-child(6) {
  animation-delay: -790ms;
}

.drop:nth-child(7) {
  animation-delay: -900ms;
}

.drop:nth-child(8) {
  animation-delay: -1050ms;
}

.drop:nth-child(9) {
  animation-delay: -1130ms;
}

.drop:nth-child(10) {
  animation-delay: -1300ms;
}

@keyframes drop {
  50% {
    height: 45px;
    opacity: 0;
  }

  51% {
    opacity: 0;
  }

  100% {
    height: 1px;
    opacity: 0;
  }
}

@media (min-width: $iPadWidth) {
  #loaderBox {
    width: 100%;
  }
}
@media (max-width: $iPadWidthL1) {
  #loaderBox {
    width: 70%;
  }
}

.loaderText.invert {
  color: $greenBlue;
}

#cloud.invert path,
#sun.invert path {
  stroke: $greenBlue;
  fill: $greenYellow;
}
.drop.invert {
  fill: $greenBlue;
}
