#myAccountBkgd {
  min-height: calc(100vh - ($hFHeight * 2));
  min-width: 100vw;
  position: relative;
  @extend %columnFlex;
  background-color: $greenYellow;
}

#emailPassword,
#nameAddress,
#startHardiness {
  @extend %columnFlex;
  margin: 30px;
}

.editBoxes {
  @extend %columnFlex;
  @extend %blue-greenBox;
  margin: 0px 0px 0px 0px;
}

.myAccountInput:disabled {
  background-color: transparent;
  text-align: center;
  color: $yellow;
  font-size: 16pt;
}

.myAccountInput {
  width: 100%;
}

@media (min-width: 935px) {
  .editBoxes {
    width: 250px;
  }
  #myAccountHeader {
    color: black;
    font-size: 30pt;
  }
  .myAccountForm {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  #nameEmailPassword {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .accountPageText {
    display: inline-block;
  }
}

@media (max-width: 934px) {
  .editBoxes {
    width: 80vw;
    max-width: 400px;
  }
  #myAccountHeader {
    display: none;
  }
  .myAccountForm {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  #emailPassword,
  #nameAddress {
    margin: 0px;
  }
}
